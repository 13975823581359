import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { useRouter } from 'next/router';
import { Experiment as MarvelExperiment, Variant as MarvelVariant } from '@marvelapp/react-ab-test';

import { GET_RESUME } from '/imports/generator/api/apollo/client/queries';
import PreviewSkeleton from '/imports/generator/ui/skeleton/PreviewSkeleton';
import PreviewWrapper from '/imports/generator/ui/pages/PreviewWrapper';
import { removeExperiment } from '/lib/helpers';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';

const ErrorOccuredDynamic = dynamic(() => import('/imports/core/ui/components/ErrorOccured'));

const PreviewPage = () => {
  const {
    screenResolution: { width },
  } = useResponsive();
  const {
    query: { resumeId },
  } = useRouter();

  const { trackEvent } = useTracking();
  const { loading, error, data } = useQuery(GET_RESUME, {
    variables: { resumeId },
  });
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [localResumeData, setLocalResumeData] = useState(null);

  useEffect(() => {
    removeExperiment('preview_page_experiment_2');
    removeExperiment('generator_resume_preview');
  }, [loading]);

  const setPageState = (currentPage, numPages) => {
    setCurrentPage(currentPage);
    setNumPages(numPages);
  };

  const onPreviousPage = () => setCurrentPage(currentPage - 1);

  const onNextPage = () => setCurrentPage(currentPage + 1);

  if (loading) return <PreviewSkeleton />;

  if (error) return <ErrorOccuredDynamic />;

  const resume = localResumeData || data.getResume;
  const setResume = (localResume, getResumeData) => {
    if (localResume) {
      setLocalResumeData({ ...localResume });
    }
    if (getResumeData) {
      return resume;
    }
  };

  return (
    <MarvelExperiment name="exp_template_budapest_v2">
      <MarvelVariant name="control">
        <PreviewWrapper
          getResume={resume}
          currentPage={currentPage}
          numPages={numPages}
          width={width}
          setPageState={setPageState}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          updateImmue={setResume}
        />
      </MarvelVariant>
      <MarvelVariant name="with_budaptest_v2">
        <PreviewWrapper
          getResume={resume}
          currentPage={currentPage}
          numPages={numPages}
          width={width}
          setPageState={setPageState}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          updateImmue={setResume}
        />
      </MarvelVariant>
    </MarvelExperiment>
  );
};

PreviewPage.propTypes = {
  router: PropTypes.object,
};

export default PreviewPage;
